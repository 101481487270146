<template>
    <div class="MyLive">
        <div class="filter-control">
            <div class="live-name">
                <label for="live-name-input">直播间名称</label>
                <el-input id="live-name-input" v-model="liveName" placeholder="请输入" clearable></el-input>
            </div>
            <div class="live-status">
                <label for="live-status-input">直播状态</label>
                <el-select id="live-status-input" v-model="status" clearable>
                    <el-option label="进行中" :value="1"></el-option>
                    <el-option label="已结束" :value="2"></el-option>
                </el-select>
            </div>
            <div class="live-time">
                <label for="live-time-input">直播时间</label>
                <el-date-picker
                    id="live-time-input"
                    v-model="liveTime"
                    type="date"
                    placeholder="请选择直播时间"
                    size="default"
                    value-format="MM/d"
                />
            </div>
        </div>
        <div class="table">
            <table-page-template
                v-bind="templateConfig"
            ></table-page-template>
        </div>
    </div>
</template>

<script>
import TablePageTemplate from "components/common/TablePageTemplate";

export default {
    name: 'MyLive',
    components: {
        TablePageTemplate
    },
    props: {},
    data() {
        return {
            liveName: "",
            status: "",
            liveTime: undefined,
            list: [{
                img: require("../../../../assets/image/studentHome/live1.png"),
                name: "手机类直播",
                statusValue: 2,
                liveName: `
                    <div style="display: flex; align-items: center;">
                        <img src="${require("../../../../assets/image/studentHome/live1.png")}" style="width: 46px; height: 46px;margin-right: 10px;" />
                        <span>手机类直播</span>    
                    </div>
                `,
                status: `<span style="color: red;">已结束</span>`,
                beginTime: "03/16 11:53",
                duration: "0小时6分30秒",
                popularityPeak: 38912,
                viewNumber: 856971,
                goodsNumber: 5,
                sale: 4282.37,
                salesVolume: 36,
            }, {
                name: "鞋靴类直播",
                statusValue: 2,
                liveName: `
                    <div style="display: flex; align-items: center;">
                        <div class="student-home-recommend">
                            <img src="${require("../../../../assets/image/studentHome/live2.png")}" style="width: 46px; height: 46px;margin-right: 10px;" />
                            <div class="recommend-icon"></div>
                        </div>
                        <span>鞋靴类直播</span>    
                    </div>
                `,
                status: `<span style="color: red;">已结束</span>`,
                beginTime: "03/20 15:38",
                duration: "0小时5分23秒",
                popularityPeak: 75638,
                viewNumber: 369751,
                goodsNumber: 12,
                sale: 25674.15,
                salesVolume: 492,
            }, {
                name: "水果类直播",
                statusValue: 2,
                liveName: `
                    <div style="display: flex; align-items: center;">
                        <img src="${require("../../../../assets/image/studentHome/live3.png")}" style="width: 46px; height: 46px;margin-right: 10px;" />
                        <span>水果类直播</span>    
                    </div>
                `,
                status: `<span style="color: red;">已结束</span>`,
                beginTime: "03/23 10:56",
                duration: "0小时8分43秒",
                popularityPeak: 1439,
                viewNumber: 17594,
                goodsNumber: 2,
                sale: 153.64,
                salesVolume: 3,
            }]
        }
    },
    computed: {
        viewList() {
            const { liveName, status, liveTime } = this;
            return this.list.filter(item => {
                let valid = {
                    name: void 0,
                    status: void 0,
                    time: void 0
                }
                valid.name = liveName ? item.name.includes(this.liveName.trim()) : true;
                valid.status = status ? item.statusValue === status : true;
                valid.time = liveTime ? item.beginTime.startsWith(liveTime.trim()) : true;
                return Object.keys(valid).every(key => valid[key]);
            })
        },
        templateConfig() {
            return {
                rightConfig: null,
                tableData: this.viewList,
                tableConfig: [
                    { label: `直播间名称`, prop: "liveName", width: 240, align: "center" },
                    { label: "直播状态", prop: "status" },
                    { label: "直播时间", prop: "beginTime" },
                    { label: "直播时长", prop: "duration" },
                    { label: "人气峰值", prop: "popularityPeak" },
                    { label: "观看人次", prop: "viewNumber" },
                    { label: "商品数", prop: "goodsNumber" },
                    { label: "销售额", prop: "sale" },
                    { label: "销量", prop: "salesVolume" },
                ],
            };
        },
    },
    methods: {
        // changeDate(value) {
        //     this.$nextTick(() => {
        //         this.$set(this, "liveTime", value)
        //     })
        // }
    }
}
</script>

<style scoped lang="scss">
.MyLive {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
    padding: 30px;
    .filter-control {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        [class^='live'] {
            display: flex;
            align-items: center;
            &:not(:last-child) {
                margin-right: 80px;
            }
            label {
                white-space: nowrap;
                margin-right: 8px;
            }
        }
    }
    .table {
        flex: 1;
        overflow: hidden;
        border: 1px solid #eee;
        border-radius: 4px;
    }
}
::v-deep .customTable td:first-child > .cell,
::v-deep .customTable th:first-child > .cell {
    padding-left: 71px !important;
}
</style>
<style lang="scss">
    .student-home-recommend {
        position: relative;
        .recommend-icon {
            position: absolute; left: 0; top: 0;
            width: 26px; height: 26px;
            background-color: #ff5552;
            clip-path: polygon(0 0, 100% 0, 0 100%);
            border-radius: 4px 0 0 0;
            &::before {
                display: block;
                content: "推荐";
                font-size: 12px; color: #fff;
                transform: rotate(-45deg) scale(0.7) translate(-5px, -3px);
            }
        }
    }
</style>
